import React from 'react';

const Learning = () => {
    return (
        <div>
            <h1 style={{color: "white"}}>Learning</h1>
        </div>
    )
}

export default Learning;